import { createCheckoutSession } from '../script/fbAPI';

import DataPlaceholder from './DataPlaceholder';
import IKONPanel from './IKONPanel';
import EventsPanel from './EventsPanel';
import Discounts from './Discounts';

import { UserAuth } from '../context/AuthContext';
import { useState, useEffect } from 'react';

import { AppState } from '../context/AppContext';
import VerifyAccount from './VerifyAccount';

import { FaExternalLinkAlt } from 'react-icons/fa';

import { products } from '../script/localdata';

const DashboardContents = () => {
    const { user } = UserAuth();
    const { setSyncing, setConfirm, verified, setVerified } = AppState();

    const [member, setMember] = useState(false);
    const [inputs, setInputs] = useState({
        schedule: false,
        num1: false,
        num2: false,
        date: false,
    });

    const handleBuyMembership = async () => {
        setSyncing(true);
        const price_id = products['24-25-membership'].price_id;
        const result = await createCheckoutSession(user, price_id);
        if (result) setSyncing(false);
    };

    // Set verified
    useEffect(() => {
        setVerified(checkAllVerified());
    }, [inputs]);

    // Set initial state of dashboard to loading while user data is synced
    useEffect(() => {
        if (JSON.stringify(user) === '{}') setSyncing(true);
    }, []);
    useEffect(() => {
        if (user.details) {
            let items = [];

            if (user.details.permissions >= 1) setMember(true);
            if (user.details.schedule) items.push('schedule');
            if (user.details.phone) items.push('num1');
            if (user.details.emergency) items.push('num2');
            if (user.details.dob) items.push('date');

            let temp = { ...inputs };
            for (var i = 0; i < items.length; i++) {
                temp[items[i]] = true;
            }
            setInputs(temp);
        }
    }, [user]);

    const setInputsCallback = (param) => {
        let temp = { ...inputs };
        temp[param] = true;
        setInputs(temp);
    };

    const handleGroupMeClickUnverified = () => {
        setConfirm({
            actionRequired: true,
            text: 'Please fill out all of your profile information before joining our GroupMe.',
            onlyConfirm: true,
        });
    };

    const checkAllVerified = () => {
        return inputs.schedule && inputs.num1 && inputs.num2 && inputs.date;
    };

    return (
        <div className='h-center'>
            <div className='content-wrapper'>
                {user && user.details && (
                    <p className='user-details'>{`${user.details.name} \u00A0\u00A0 | \u00A0\u00A0 ${user.details.email} \u00A0\u00A0 | \u00A0\u00A0 ${user.uid}`}</p>
                )}
                <h1>Dashboard</h1>
                {!member && (
                    <div>
                        {/* Non-member events */}
                        <EventsPanel override={true} />
                        {/* Membership sign up */}
                        <div>
                            <h2>24/25 Membership (Early-Access)</h2>
                            <p>
                                {
                                    "The 24/25 membership is valid beginning Fall quarter 2024 until the end of Spring quarter 2025, but by purchasing it now you'll still receive early access to your 24/25 IKON code."
                                }
                            </p>
                            <br />
                            <p>
                                {
                                    'The purchase of a 24/25 membership does NOT grant you access to Spring quarter 2024 LASBC events. Please DM us on Instagram if you have any questions.'
                                }
                            </p>
                            <div className='product-wrapper'>
                                <div className='product-info'>
                                    <p className='product-title'>
                                        24/25 LASBC Membership
                                    </p>
                                    <p className='product-price'>$35 + $2 processing fee</p>
                                </div>
                                <button
                                    className='primary'
                                    onClick={handleBuyMembership}
                                >
                                    Become a Member
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {member && (
                    <div className='relative'>
                        <div className='info-wrapper'>
                            <div className='flex'>
                                <h2>My Account</h2>
                            </div>
                            <VerifyAccount callback={setInputsCallback} />
                        </div>
                        {/* Staff Applications */}
                        <div className='info-wrapper'>
                            <h2>Staff Applications</h2>
                            <p>
                                Interested in getting more involved in our club?
                                Apply to become a member of staff!
                            </p>
                            <br></br>
                            <p>
                                Our staff members are the face of the club.
                                On top of keeping the vibes high, they help out behind the scenes to plan parties, cabin trips, 
                                tailgates, and other club events. If you think you can match our freak, apply through the link below...
                            </p>
                            <a
                                href={
                                    'https://forms.gle/NVJoYdbTEu8Zv6YY8'
                                }
                                target='_blank'
                            >
                                <button className='secondary'>
                                    Apply now
                                    <FaExternalLinkAlt className='inline-icon' />
                                </button>
                            </a>
                        </div>
                        {/* Member GroupMe */}
                        {user.details && !user.details.hasPaidDuesEarly && (
                            <div className='info-wrapper'>
                                <h2>Member GroupMe</h2>
                                {verified && (
                                    <div>
                                        <p>
                                            Join our member GroupMe for real-time
                                            updates from our staff and to chat with
                                            other members.
                                        </p>
                                        <a
                                            href={
                                                'https://groupme.com/join_group/103528656/GvHqOMSg'
                                            }
                                            target='_blank'
                                        >
                                            <button className='secondary'>
                                                GroupMe
                                                <FaExternalLinkAlt className='inline-icon' />
                                            </button>
                                        </a>
                                    </div>
                                )}
                                {!verified && <DataPlaceholder locked={true} />}
                            </div>
                        )}
                        {/* Events */}
                        <EventsPanel />
                        {/* IKON Panel */}
                        <div className='info-wrapper'>
                            <h2>IKON Panel</h2>
                            <IKONPanel inputs={inputs} />
                        </div>
                        {/* Discounts */}
                        <div className='info-wrapper'>
                            <h2>Discounts</h2>
                            <Discounts />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardContents;
